import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { PastAnswersDisplay } from './past-answers-display';
import { GenerationView } from './generation';
import { SimilarPair } from '@/types';
import React, { useEffect } from 'react';
import { ShortcutKey } from '@/components/minimal-tiptap/components/shortcut-key';
import { useDDQState } from '../use-ddq-state';
import HelpHint from '@/components/info/help-hint';
import { Button } from '@/components/ui/button';
import { captureEvent } from '@/lib/analytics';
import { useParams } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { Separator } from '@/components/ui/separator';

interface AssistantPanelProps {
  selectedPair: SimilarPair;
}

export const AssistantPanel = ({ selectedPair }: AssistantPanelProps) => {
  const [tabValue, setTabValue] = React.useState('search');

  const {
    state: [ddqState],
    pairObj,
    answerObj: selectedAnswerObj,
    answerPagination,
    setSelectedAnswer
  } = useDDQState();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const activeElement = document.activeElement as Element & HTMLElement;
      const isEditable = activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.isContentEditable);

      if (isEditable) return;

      if (event.key === 's') {
        setTabValue('search');
      } else if (event.key === 'g') {
        setTabValue('generate');
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const pair = pairObj ? pairObj[0] : null;

  const answers = pairObj && pairObj[1];
  const hasAnswers =
    typeof answers !== 'undefined' &&
    typeof answers?.length !== 'undefined' &&
    answers.length > 0;

    const selectedAnswerIndex =
      selectedAnswerObj && answers ? answers?.indexOf(selectedAnswerObj) : -1;

  const { uuid: ddqId } = useParams();

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isContentEditable =
        activeElement && (activeElement as HTMLElement).isContentEditable;

      if (!isContentEditable) {
        if (
          event.key === ',' &&
          pair &&
          hasAnswers &&
          selectedAnswerIndex !== -1
        ) {
          captureEvent('analysisClickAnswerArrow', {
            doc_id: ddqId || '',
            pair_id: ddqState.selectedQuestion || '',
            direction: 'prev',
            index: selectedAnswerIndex
          });

          if (answerPagination.prev !== undefined) {
            setSelectedAnswer(answerPagination.prev);
          } else {
            setSelectedAnswer(null);
          }
        } else if (
          event.key === '.' &&
          pair &&
          hasAnswers &&
          selectedAnswerIndex < answers.length - 1
        ) {
          captureEvent('analysisClickAnswerArrow', {
            doc_id: ddqId || '',
            pair_id: ddqState.selectedQuestion || '',
            direction: 'next',
            index: selectedAnswerIndex
          });

          if (answerPagination.next !== undefined) {
            setSelectedAnswer(answerPagination.next);
          }
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    pair,
    hasAnswers,
    answerPagination,
    setSelectedAnswer,
    ddqId,
    ddqState.selectedQuestion,
    answers,
    selectedAnswerIndex
  ]);

  return (
    <Tabs
      value={tabValue}
      onValueChange={setTabValue}
      className='flex max-h-full min-h-full flex-col overflow-x-hidden'
    >
      <div className='flex items-center px-4 py-2 justify-between'>
        <TabsList>
          <TabsTrigger value='search'>
            Search <ShortcutKey keys={['s']} />
          </TabsTrigger>
          <TabsTrigger value='generate'>
            Generate <ShortcutKey keys={['g']} />
          </TabsTrigger>
        </TabsList>
        <div className={'ml-auto flex items-center gap-2' + (tabValue !== 'search' ? ' invisible' : '')}>
          {
            <span className='text-muted-foreground'>
              {hasAnswers ? selectedAnswerIndex + 1 : '--'} of{' '}
              {answers?.length || 0}
            </span>
          }
          <HelpHint>
            <p>
              This number includes highly-relevant and lowly-relevant search
              results.{' '}
            </p>
            <br />
            <p>
              High relevance answers appear first and low relevance answers
              appear next, then everything is presented in descending approved
              date order.
            </p>
          </HelpHint>
          {!pair && <Button className='invisible' />}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={!hasAnswers || selectedAnswerIndex === -1}
              onClick={() => {
                captureEvent('analysisClickAnswerArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'prev',
                  index: selectedAnswerIndex
                });

                if (answerPagination.prev !== undefined)
                  { setSelectedAnswer(answerPagination.prev); } else {
                    setSelectedAnswer(null);
                  }
              }}
            >
              <ArrowLeftIcon />
              <ShortcutKey keys={[',']} />
            </Button>
          )}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={
                !hasAnswers || selectedAnswerIndex >= answers.length - 1
              }
              onClick={() => {
                captureEvent('analysisClickAnswerArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'next',
                  index: selectedAnswerIndex
                });

                if (answerPagination.next !== undefined)
                  setSelectedAnswer(answerPagination.next);
              }}
            >
              <ArrowRightIcon />
              <ShortcutKey keys={['.']} />
            </Button>
          )}
        </div>
      </div>
      <Separator />
      <TabsContent className='flex-grow max-h-full relative overflow-y-hidden' value='search'>
        <PastAnswersDisplay />
      </TabsContent>
      <TabsContent className='flex-grow max-h-full overflow-y-scroll relative' value='generate'>
        <GenerationView selectedPair={selectedPair} />
      </TabsContent>
    </Tabs>
  );
};
