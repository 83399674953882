import { Button, ButtonLoading } from '@/components/ui/button';

import { Markdown } from '@/components/generic/markdown';

import '@/css/display.css';
import { useDDQState } from '../use-ddq-state';
import HighlightCopy from '@/components/generic/highlight-copy';
import { CopyCitation } from '../../generic/copy-citation';
import { useCallback, useRef, useEffect } from 'react';
import { ArrowDown, Loader2 } from 'lucide-react';
import { RerenderEditorContentHandle } from '@/components/minimal-tiptap/components/minimal-tiptap';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEditorState } from '../use-editor-state';
import {
  getProcessingState,
  useProgressiveSearch
} from '../progressive-search';
import ContentLabel from '@/components/info/content-label';
import AnswerColumns from '@/components/generic/answer-columns';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';
import { ShortcutKey } from '@/components/minimal-tiptap/components/shortcut-key';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line
export const PastAnswersDisplay = () => {
  const {
    state: [ddqState],
    currentQuestionData,
    updateQuestionData,
    pairObj,
    answerObj: selectedAnswerObj,
    setSelectedAnswer,
    mutateCurrentEditStateWithCopy,
    addSelectedPairForGeneration,
    removeSelectedPairForGeneration,
    isCurrentAnswerCopying,
    setReplacementTextConditionally
  } = useDDQState();

  const { replacementText, resetReplacementText } = useEditorState();

  const editorRef = useRef<RerenderEditorContentHandle>(null);

  useEffect(() => {
    if (replacementText.length > 0) {
      editorRef.current?.rerender(replacementText[replacementText.length - 1]);
      resetReplacementText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementText]);

  const pair = pairObj ? pairObj[0] : null;

  const answers = pairObj && pairObj[1];

  const onAnswerCopy = useCallback(
    async (copyPairId: string) => {
      if (!pair) return;

      console.log('answer copy', pair.id, copyPairId);

      mutateCurrentEditStateWithCopy
        .mutateAsync({ copyPairId, id: pair.id })
        .then((value) => {
          updateQuestionData(pair.id, { content: value.text || '' });
          setReplacementTextConditionally({ id: pair.id, content: value.text });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pair]
  );

  const { results, requestProcessingPair } = useProgressiveSearch();

  const searchStatus = getProcessingState(results, pair?.id || '');

  useEffect(() => {
    if (
      pairObj &&
      getProcessingState(results, pairObj[0].id) &&
      !searchStatus.processing &&
      !searchStatus.complete
    ) {
      requestProcessingPair(pairObj);
    }
  }, [pairObj, results, requestProcessingPair, searchStatus]);

  const completeWithoutFailure = searchStatus.complete && !searchStatus.failed;
  const failedSearch = !searchStatus.processing && searchStatus.failed;
  const hasResponses = Math.max(searchStatus.response?.length || 0, 0) > 0;

  const selectWithScroll = (v: string) => {
    setSelectedAnswer(v || null);
  };

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(selectedAnswerObj?.pair.id || '')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  }, [selectedAnswerObj]);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      const activeElement = document.activeElement as Element & HTMLElement;
      const isEditable = activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.isContentEditable);

      if (!isEditable && event.key === 'S') {
        console.log('alt s key up');

        if (selectedAnswerObj) {
          const isSelected = currentQuestionData?.selectedAnswersForGeneration.includes(selectedAnswerObj.pair.id);
          if (isSelected) {
            removeSelectedPairForGeneration(selectedAnswerObj.pair.id);
          } else {
            addSelectedPairForGeneration(selectedAnswerObj.pair.id);
          }
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [selectedAnswerObj, currentQuestionData, addSelectedPairForGeneration, removeSelectedPairForGeneration]);

  return (
    <div className='h-full w-full overflow-y-hidden absolute top-0 left-0'>
      <>
        {completeWithoutFailure && (
          <>
            {hasResponses ? (
              <div className='px-4 h-full w-full'>
                
                  <div className='flex flex-col items-start gap-4 text-sm w-full max-h-full'>
                    {/* <AnswerSelector
                      answers={answers || []}
                      onSelect={setSelectedAnswer}
                      selectedAnswerObj={selectedAnswerObj}
                      contextCategoryId={ddqState.analysisResponse?.category_id}
                    /> */}
                    <div className='flex items-center bg-white sticky top-0 left-0 w-full h-4 text-gray-500'>
                      <ArrowDown className='h-4 w-4 mr-2' /> Save source for
                      research.<ShortcutKey keys={['shift', 'S']} />
                    </div>
                    <div className='flex flex-col flex-grow h-full overflow-y-scroll w-full'>
                      <Accordion
                        type='single'
                        collapsible
                        className='w-full scroll-pb-80'
                        value={selectedAnswerObj?.pair.id || ''}
                        onValueChange={selectWithScroll}
                      >
                        {answers?.map((a) => (
                          <AccordionItem key={a.pair.id} value={a.pair.id} className='w-full'>
                            <AccordionTrigger className='w-full !no-underline'>
                              <AnswerColumns className='w-full'>
                                <div className='flex items-center relative ml-8'>
                                  <div
                                    className='h-8 w-8 flex items-center absolute top-0 -left-8'
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseEnter={(e) => e.stopPropagation()}
                                  >
                                    <Checkbox
                                      checked={
                                        currentQuestionData?.selectedAnswersForGeneration.indexOf(
                                          a.pair.id
                                        ) !== -1
                                      }
                                      onCheckedChange={(checked) => {
                                        !checked
                                          ? removeSelectedPairForGeneration(
                                              a.pair.id
                                            )
                                          : addSelectedPairForGeneration(
                                              a.pair.id
                                            );
                                      }}
                                    />
                                  </div>
                                  <ContentLabel
                                    id={a.pair.id}
                                    className='cursor-pointer'
                                    data={a}
                                    contextCategoryId={
                                      ddqState.analysisResponse?.category_id
                                    }
                                    fromSearch={
                                      currentQuestionData?.savesFromSearch.includes(a)
                                    }
                                  />
                                </div>
                                <></>
                              </AnswerColumns>
                            </AccordionTrigger>
                            <AccordionContent>
                              <AnswerColumns className='w-full mb-2 overflow-x-scroll overflow-y-hidden'>
                                <>
                                  <Markdown
                                    className='markdown-els max-w-6xl mt-3 border-l pl-2 h-full ml-8'
                                    content={a.pair.content}
                                    ddqId={a.pair.ddq_id}
                                  />
                                </>
                                <>
                                  <div className='flex-grow flex justify-end gap-2 mt-2'>
                                    {a.pair.ddq.approved_date &&
                                      (isCurrentAnswerCopying ? (
                                        <ButtonLoading>
                                          Copy to Editor
                                        </ButtonLoading>
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            onAnswerCopy(a.pair.id)
                                          }
                                        >
                                          Copy to Editor
                                        </Button>
                                      ))}
                                  </div>
                                  {a.pair.ddq.approved_date && (
                                    <>
                                      <CopyCitation
                                        friendlyName={a.pair.ddq.friendly_name}
                                        approvedDate={a.pair.ddq.approved_date.slice(
                                          0,
                                          10
                                        )}
                                      />
                                    </>
                                  )}
                                  <HighlightCopy
                                    hiddenChildren
                                    copyTitle='Copy Content'
                                  >
                                    <Markdown
                                      className='markdown-els'
                                      content={a.pair.content}
                                      ddqId={a.pair.ddq_id}
                                    />
                                  </HighlightCopy>
                                </>
                              </AnswerColumns>
                            </AccordionContent>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                
              </div>
            ) : (
              <div className='flex flex-[1_1_0] items-center justify-center px-4 overflow-y-scroll'>
                <div>
                  <p>No relevant content was found with the auto-search. </p>
                  <p className='italic text-gray-500 text-sm'>
                    Retry or use the search bar above with your own query.
                  </p>
                </div>

                <Button
                  className='ml-2'
                  variant={'outline'}
                  onClick={() => {
                    pairObj && requestProcessingPair(pairObj);
                  }}
                >
                  Click to retry.
                </Button>
              </div>
            )}
          </>
        )}
        {searchStatus.processing && (
          <>
            <div className='flex flex-[1_1_0] items-center justify-center px-4 overflow-y-scroll cursor-wait'>
              <Loader2 className={'h-6 w-6 mr-2 animate-spin'} />{' '}
              <div>
                <p>
                  Auto-discovering relevant approved content from your
                  database...
                </p>
                <p className='italic text-gray-500 text-sm'>
                  Or use the search bar above with your own query.
                </p>
              </div>
            </div>
          </>
        )}
        {failedSearch && (
          <>
            <div className='flex flex-[1_1_0] items-center justify-center px-4 overflow-y-scroll'>
              <div>
                <p>The search failed.</p>
                <p className='italic text-gray-500 text-sm'>
                  Retry or use the search bar above with your own query.
                </p>
              </div>
              <Button
                className='ml-2'
                variant={'outline'}
                onClick={() => {
                  pairObj && requestProcessingPair(pairObj);
                }}
              >
                Click to retry.
              </Button>
            </div>
          </>
        )}
      </>
    </div>
  );
};
