import { atomWithStorage } from 'jotai/utils';
import { useAtom, atom } from 'jotai';
import { validate as isUuid } from 'uuid';
import { SimilarPair } from '@/types';

type FilterOptions = Pick<ReturnType<typeof useFilterState>, 'filterOnOff' | 'selectedCategory' | 'selectedContentRangeDate'>;

// Atom to store the filter on/off state
export const filterOnOffAtom = atomWithStorage<boolean>('filterOnOff', false);

// Basic atom to store the selected category
export const selectedCategoryAtom = atom<string>('');

// Atom to store the selected content range with limited options
export const selectedContentRangeAtom = atomWithStorage<'lastQuarter' | 'thisYear' | 'lastYear' | 'forever' | string>('selectedContentRange', 'forever');

// Getter atom to define the date as an ISO date string based on the selected content range
export const selectedContentRangeDateAtom = atom((get) => {
  const selectedRange = get(selectedContentRangeAtom);
  const currentDate = new Date();
  let date;

  if (selectedRange === 'lastQuarter') {
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3);
    const lastQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
    const lastQuarterYear = currentQuarter === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    date = new Date(lastQuarterYear, lastQuarter * 3, 1);
  } else if (selectedRange === 'thisYear') {
    date = new Date(currentDate.getFullYear(), 0, 1);
  } else if (selectedRange === 'lastYear') {
    date = new Date(currentDate.getFullYear() - 1, 0, 1);
  } else {
    date = new Date(0); // Represents the earliest possible date
  }

  return date.toISOString().split('T')[0]; // Return date as yyyy-mm-dd
});

export function filterResults(items: SimilarPair[1], { filterOnOff, selectedCategory, selectedContentRangeDate }: FilterOptions): SimilarPair[1] {
  if (!filterOnOff) {
    return items;
  }

  const isCategoryUuid = isUuid(selectedCategory || '');
  return items.filter((pair) => {
    const matchesCategory = isCategoryUuid ? pair.pair.ddq.category_id === selectedCategory : true;
    const matchesDateRange = new Date(pair.pair.ddq.approved_date) >= new Date(selectedContentRangeDate || '');

    return matchesCategory && matchesDateRange;
  });
}

export function useFilterState() {
  const [filterOnOff, setFilterOnOff] = useAtom(filterOnOffAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [selectedContentRange, setSelectedContentRange] = useAtom(selectedContentRangeAtom);
  const selectedContentRangeDate = useAtom(selectedContentRangeDateAtom)[0];

  return {
    filterOnOff,
    setFilterOnOff,
    selectedCategory,
    setSelectedCategory,
    selectedContentRange,
    setSelectedContentRange,
    selectedContentRangeDate
  };
}
