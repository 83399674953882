'use client';
import * as React from 'react';

import { useDDQState } from '@/components/ddq/use-ddq-state';
import { Separator } from '@/components/ui/separator';

import { SimilarPair } from '@/types';

import { Textarea } from '@/components/ui/textarea';
import { Button, ButtonLoading } from '@/components/ui/button';
import { useAuthInfo } from '@propelauth/react';
import { Markdown } from '@/components/generic/markdown';
import HighlightCopy from '@/components/generic/highlight-copy';
import { captureEvent, runTimerInMs } from '@/lib/analytics';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import HelpHint from '@/components/info/help-hint';
import AnswerColumns from '@/components/generic/answer-columns';

export const GenerationView: React.ComponentType<{
  selectedPair: SimilarPair;
}> = ({ selectedPair }) => {
  const [loading, setLoading] = React.useState(false);

  const {
    state: [ddqState],
    pairObj,
    mutateCurrentEditStateWithGeneration,
    isCurrentAnswerSavingGeneration,
    updateQuestionData,
    setReplacementTextConditionally,
    currentQuestionData,
    generationInstructions,
    setGenerationInstructions,
    generationResult: generation,
    setGenerationResult: setGeneration
  } = useDDQState();

  const { accessToken } = useAuthInfo();

  const onGenerate = async () => {
    setLoading(true);
    setGeneration('');

    const timer = runTimerInMs();
    try {
      if (!selectedPair) {
        console.log('no selected pair', selectedPair);
        return;
      }
      if (currentQuestionData?.selectedAnswersForGeneration.length === 0) {
        console.log('no selected answers', currentQuestionData?.selectedAnswersForGeneration);
        return;
      }

      captureEvent('generateAnswerSubmit', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources: currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || ''
      });

      const body = {
        context_pairs: currentQuestionData?.selectedAnswersForGeneration.filter(id => 
          selectedPair[1].some(answer => answer.pair.id === id) || 
          currentQuestionData.savesFromSearch.some(save => save.pair.id === id)
        ),
        instructions: generationInstructions
      };

      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${selectedPair[0].id}/draft`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      );

      if (!response.ok) {
        throw new Error('Failed to search responses');
      }

      const results = await response.json();

      setGeneration(results.generation);

      captureEvent('generateAnswerSuccess', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources: currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || '',
        loadTime: timer(),
        result: results.generation
      });
    } catch (e) {
      captureEvent('generateAnswerFailure', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources:
          currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || '',
        loadTime: timer(),
        error: String(e)
      });
    } finally {
      setLoading(false);
    }
  };

  const hasGeneration =
    !!generation;
  const isLoading = loading;
  const generatedResult = generation;

  const pair = pairObj ? pairObj[0] : null;

  const onSaveGeneration = React.useCallback(
    async (content: string, sourcePairIds: string[]) => {
      if (!pair) return;

      console.log('save answer generation', pair.id, content, sourcePairIds);

      mutateCurrentEditStateWithGeneration
        .mutateAsync({ id: pair.id, content, sourcePairIds })
        .then((value) => {
          updateQuestionData(pair.id, { content: value.text || '' });
          setReplacementTextConditionally({ id: pair.id, content: value.text });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pair]
  );

  return (
    <div className='h-full w-full overflow-hidden flex-col absolute top-0 left-0 flex'>
      <div className='flex-grow overflow-y-hidden p-2'>
        {hasGeneration && (
          <AnswerColumns scrollLeftColumn className='overflow-y-hidden'>
            <>
              <Markdown
                className='mx-2 my-1 p-2 border rounded prose markdown-els bg-gray-100 overflow-y-scroll w-full'
                content={generatedResult || ''}
              />
            </>
            <>
              {typeof generatedResult === 'string' &&
                (isCurrentAnswerSavingGeneration ? (
                  <ButtonLoading>Copy to Editor</ButtonLoading>
                ) : (
                  <Button
                    onClick={() =>
                      onSaveGeneration(
                        generatedResult,
                        currentQuestionData?.selectedAnswersForGeneration || []
                      )
                    }
                  >
                    Copy to Editor
                  </Button>
                ))}
              <HighlightCopy hiddenChildren copyTitle='Copy Content'>
                <Markdown
                  className='markdown-els'
                  content={generatedResult || ''}
                />
              </HighlightCopy>
            </>
          </AnswerColumns>
        )}
      </div>
      <Separator />
      <div className='flex flex-col flex-shrink-0 p-4'>
        <div className='px-4 pt-2 text-sm font-semibold'>
          Generating with the sources you saved in search, optionally add
          instructions: &nbsp;
          <HelpHint>
            <p>
              If you don't provide any instructions here, the AI will simply try
              to answer the question.
            </p>
          </HelpHint>
        </div>
        <div className='flex'>
          <Textarea
            className='m-1'
            value={generationInstructions}
            onChange={(e) => setGenerationInstructions(e.target.value)}
            placeholder='Generation Instructions, for example:
- write this in 2 sentences
- focus on sustainability
- make a table'
            disabled={isLoading}
            rows={4}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                !e.metaKey &&
                !e.shiftKey &&
                !e.altKey &&
                !e.ctrlKey
              ) {
                onGenerate();
              } else if (e.key === 'Enter') {
                setGenerationInstructions(generationInstructions + '\n');
              }
            }}
          />
        </div>
        <div className='self-end'>
          {!isLoading ? (
            currentQuestionData?.selectedAnswersForGeneration.length === 0 ? (
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      className='my-1 w-full'
                      onClick={onGenerate}
                      disabled
                    >
                      Generate
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side='top'>
                    <p>Select answers to use for generation</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <Button className='m-1' onClick={onGenerate}>
                Generate
              </Button>
            )
          ) : (
            <ButtonLoading className='m-1'>Generating</ButtonLoading>
          )}
        </div>
      </div>
    </div>
  );
};
